import React, { useState } from 'react';
import { Button, Form, Header, Input, Message, Segment } from 'semantic-ui-react';
import { API, showError, showSuccess } from '../../helpers';

const AddSubscription = () => {
  const [loading, setLoading] = useState(false);
  // 获取下个月的今天作为默认的订阅结束日期
  const nextMonthToday = new Date();
  nextMonthToday.setMonth(nextMonthToday.getMonth() + 1);
  const defaultEndDate = nextMonthToday.toISOString().split('T')[0];
  const [subscription, setSubscription] = useState({
    Username: '',
    Cdkey: '',
    ResetDate: 1,
    SubscriptionEndDate: defaultEndDate,
    UserGroup: ''
  });

  const handleInputChange = (e, { name, value }) => {
    if (name === 'ResetDate') {
      value = parseInt(value); // 将字符串类型转换为数字类型
    }
    setSubscription(prevState => ({ ...prevState, [name]: value }));
  };

  const setSubscriptionEndDate = (days) => {
    let endDate = new Date();
    endDate.setDate(endDate.getDate() + days);
    const formattedDate = endDate.toISOString().split('T')[0];
    setSubscription({ ...subscription, SubscriptionEndDate: formattedDate });
  };

  const submit = async () => {
    setLoading(true);
    try {
      const res = await API.post('/api/subscription/add', subscription);
      const { success, message } = res.data;
      if (success) {
        showSuccess('订阅添加成功！');
        setSubscription({
          Username: '',
          Cdkey: '',
          ResetDate: 1,
          SubscriptionEndDate: defaultEndDate,
          UserGroup: ''
        });
      } else {
        showError(message);
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Segment loading={loading}>
      <Header as='h3'>添加订阅</Header>
      <Form>
        <Form.Field>
          <Form.Input
            label='用户名'
            required
            name='Username'
            placeholder='请输入用户名'
            onChange={handleInputChange}
            value={subscription.Username}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label='Cdkey'
            required
            name='Cdkey'
            placeholder='请输入CDKey'
            onChange={handleInputChange}
            value={subscription.Cdkey}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label='重置日期'
            required
            name='ResetDate'
            placeholder='请输入重置日期'
            onChange={handleInputChange}
            value={subscription.ResetDate}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label='订阅结束日期'
            required
            name='SubscriptionEndDate'
            placeholder='请输入订阅结束日期'
            onChange={handleInputChange}
            value={subscription.SubscriptionEndDate}
            type='date'
          />
        </Form.Field>
        <div style={{ lineHeight: '40px' }}>
          <Button type={'button'} onClick={() => {
            setSubscriptionEndDate(30);
          }}>一个月后过期</Button>
        </div>
        <Form.Field>
          <Form.Input
            label='用户组'
            name='UserGroup'
            placeholder='请输入用户组'
            onChange={handleInputChange}
            value={subscription.UserGroup}
          />
        </Form.Field>
        <Button type='submit' positive onClick={submit}>提交</Button>
      </Form>
    </Segment>
  );
};

export default AddSubscription;
