import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import SubscriptionTable from '../../components/SubscriptionTable';

const File = () => (
  <>
    <Segment>
      <Header as='h3'>管理订阅</Header>
      <SubscriptionTable />
    </Segment>
  </>
);

export default File;
