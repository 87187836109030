import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Table, Pagination, Modal, Icon } from 'semantic-ui-react';
import { API, showError, showSuccess, timestamp2string } from '../helpers';
import { Link } from 'react-router-dom';

const ITEMS_PER_PAGE = 10;

function renderTimestamp(timestamp) {
  return (
    <>
      {timestamp2string(timestamp)}
    </>
  );
}

const SubscriptionsTable = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [modalOpen, setModalOpen] = useState(false); // For add/edit modal
  const [canLoadMore, setCanLoadMore] = useState(true); // State to determine if more data can be loaded

  const loadSubscriptions = async (page) => {
    setLoading(true);
    const res = await API.get(`/api/subscription/?p=${page}`);
    const { success, message, data } = res.data;
    if (success) {
      setSubscriptions(data);
      setCanLoadMore(data.length === ITEMS_PER_PAGE); // If data length is equal to ITEMS_PER_PAGE, assume there's a next page
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const onPaginationChange = (e, pageInfo) => {
    const newActivePage = pageInfo.activePage;
    setActivePage(newActivePage);

    if (newActivePage > activePage && canLoadMore) {
      // Only load more data if we're moving forward and we can load more
      loadSubscriptions(newActivePage - 1);
    }
  };

  useEffect(() => {
    loadSubscriptions(0); // Load the first page of data on component mount
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await API.delete(`/api/subscription/${id}`);
      if (res.data.success) {
        showSuccess("Subscription deleted successfully!");
        loadSubscriptions((activePage - 1) * 10);
      } else {
        showError(res.data.message);
      }
    } catch (error) {
      showError("Error deleting subscription.");
    }
  };

  return (
    <>
      <Button size='small' as={Link} to='/subscription/add' loading={loading}>
        添加订阅
      </Button>
      <Form>
        <Form.Input
          icon='search'
          fluid
          iconPosition='left'
          placeholder='搜索订阅的 ID，用户名和 CDKey ...'
          value={searchKeyword}
          onChange={(e, { value }) => setSearchKeyword(value.trim())}
        />
      </Form>
      <Table basic compact size='small'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>用户名</Table.HeaderCell>
            <Table.HeaderCell>key</Table.HeaderCell>
            <Table.HeaderCell>订阅类型</Table.HeaderCell>
            <Table.HeaderCell>每月额度重置日期</Table.HeaderCell>
            <Table.HeaderCell>订阅结束日期</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {subscriptions.map((subscription, idx) => (
            <Table.Row key={subscription.Id}>
              <Table.Cell>{subscription.Username ? subscription.Username : '无'}</Table.Cell>
              <Table.Cell>{subscription.Cdkey ? subscription.Cdkey : '无'}</Table.Cell>
              <Table.Cell>{subscription.UserGroup ? subscription.UserGroup : '无'}</Table.Cell>
              <Table.Cell>{subscription.ResetDate ? subscription.ResetDate : '无'}</Table.Cell>
              <Table.Cell>{subscription.SubscriptionEndDate ? subscription.SubscriptionEndDate : '无'}</Table.Cell>              <Table.Cell>
                <Button icon onClick={() => setModalOpen(true)}>
                  <Icon name='edit' />
                </Button>
                <Button icon color='red' onClick={() => handleDelete(subscription.Id)}>
                  <Icon name='delete' />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        {/* ... (rest of the table code) */}
      </Table>
      <Pagination
        activePage={activePage}
        onPageChange={onPaginationChange}
        size='small'
        siblingRange={1}
        // Use canLoadMore to determine if the "next" button should be shown
        // totalPages is set to current page + 1 if canLoadMore is true, otherwise just current page
        totalPages={canLoadMore ? activePage + 1 : activePage}
        // Disable "next" button if we cannot load more
        nextItem={canLoadMore ? undefined : { disabled: true }}
      />
    </>
  );
};

export default SubscriptionsTable;
